.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    position: relative !important;
}

.select2-container {
    width: 100% !important;
}

.select2-container .select2-selection--single {
    height: 42px !important;
}
