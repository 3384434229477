@page {
    size: A4;
    margin: 0;
  }
  .body{
    font-family: Helvetica;
    -webkit-font-smoothing: antialiased;
    padding-top:30px;
    height: "auto";
    color: black;
}

.p-text{
  line-height: 1.4; 
  font-weight: bold; 
  margin-top: 2px
}
.table-wrapper{
    margin: 125px 15px 7px;
    box-shadow: 0px 5px 5px rgba( 0, 0, 0, 0 );
    text-align:justify;
    font-size: 15px;
    line-height: 1.8;
    padding: 14px;
    padding-bottom: 109px;
    height:'auto';
    background-color: 'transparent !important';
    color: rgb(48, 48, 48);
}
.title{
    text-align: center;
    color: black;
    font-size:18px;
    font-weight: bolder;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top:-25px;
    text-decoration:underline
}
/* .bg{
    background-image: url("./baba-ahmed-admission_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-color: 'transparent !important' 
} */

.print-source {
    display: none;
    background-color: red;
  }
  
  body > .print-source {
    display: block;
  }
  
  @media print {
    .print-source {
      display: block;
      background-color: green;
    }
  }